// extracted by mini-css-extract-plugin
export var background = "spamenu-module--background--trgWE";
export var bodytreatments = "spamenu-module--bodytreatments--C-x7+";
export var container = "spamenu-module--container--t5eXh";
export var facials = "spamenu-module--facials--pQp5J";
export var info = "spamenu-module--info--PiAVV";
export var massages = "spamenu-module--massages--5f-G-";
export var packages = "spamenu-module--packages--tDgbO";
export var price = "spamenu-module--price--J1fDG";
export var title = "spamenu-module--title--boI3f";
export var treatment1 = "spamenu-module--treatment1--5yk3o";
export var treatment2 = "spamenu-module--treatment2--GZvC4";
export var treatment3 = "spamenu-module--treatment3--tcNzQ";
export var treatmentTitle = "spamenu-module--treatmentTitle--1pBZi";